import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/organizationActions';
import {type Organization} from '../types';

interface OrganizationsState {
  loading: boolean;
  items: Record<string, Organization | null>;
  userRoles: {
    orgId: string;
    userId: string;
    role: string;
  }[];
}

const initalState = {
  loading: true,
  items: {},
  userRoles: [],
} as OrganizationsState;

const organizationsReducer = createReducer(initalState, (builder) => {
  builder
    .addCase(actions.getOrganizationInfoRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.getOrganizationInfoFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.getOrganizationInfoSuccess, (state, action) => {
      state.loading = false;
      state.items[action.payload.org.id] = action.payload.org;
      state.userRoles = state.userRoles.filter(
        (userRole) =>
          !(action.payload.users || []).find(
            (user) => userRole.orgId === action.payload.org.id && userRole.userId === user.id,
          ),
      );
      state.userRoles.push(
        ...(action.payload.users || []).map((user) => ({
          orgId: action.payload.org.id,
          userId: user.id,
          role: user.role,
        })),
      );
    })
    .addCase(actions.patchOrgSuccess, (state, action) => {
      state.items[action.payload.org.id] = action.payload.org;
    })
    .addCase(actions.patchUserOrgRoleSuccess, (state, action) => {
      const userRole = state.userRoles.find(
        (role) =>
          role.orgId === action.payload.organizationId && role.userId === action.payload.userId,
      );
      if (userRole) {
        userRole.role = action.payload.role;
      }
    });
});

export default organizationsReducer;
