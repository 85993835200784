import {Enterprise, Folder, User} from '@carbon/icons-react';
import {Box, Divider, ListItemButton, ListItemText, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import Pinecone from '../icons/pinecone_logo_small_light.svg';
import UserMenu from './UserMenu';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    width: 300,
    backgroundColor: '#20354F',
  },
  navigationItem: {
    my: 1,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    display: 'grid',
    placeItems: 'center',
    height: 100,
    transform: 'scale(1.25)',
  },
  userMenu: {
    marginTop: 'auto',
  },
};

type NavigationItemType = {
  icon: React.ReactNode;
  name: string;
  secondaryText?: string;
  path: string;
};
function NavigationItem({icon, name, secondaryText = '', path}: NavigationItemType) {
  const navigate = useNavigate();
  const location = useLocation();
  const selected = path === location.pathname || `${path}/` === location.pathname;
  return (
    <ListItemButton sx={styles.navigationItem} selected={selected} onClick={() => navigate(path)}>
      <Box sx={{color: 'secondary', pr: 2}}>{icon}</Box>
      <ListItemText primary={name} secondary={secondaryText} />
    </ListItemButton>
  );
}

function NavigationSidebar() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.logo}>
        <Box src={Pinecone} component="img" />
      </Box>
      <Typography variant="h4" textAlign="center">
        Admin Dashboard
      </Typography>
      <Divider sx={{my: 2}} />
      <Box>
        <NavigationItem
          icon={<User size="24" />}
          name="Users"
          secondaryText="Search for Users"
          path="/users"
        />
        <NavigationItem
          icon={<Folder size="24" />}
          name="Projects"
          secondaryText="Search for Projects"
          path="/projects"
        />
        <NavigationItem
          icon={<Enterprise size="24" />}
          name="Organizations"
          secondaryText="Search for Orgs"
          path="/organizations"
        />
      </Box>
      <Box sx={styles.userMenu}>
        <Divider sx={{my: 2}} />
        <UserMenu />
      </Box>
    </Box>
  );
}
export default NavigationSidebar;
