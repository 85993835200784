import {Box, Divider, Grid, Typography} from '@mui/material';
import {getAccentColor} from '../../../styles/utils';
import StyledChip from '../../StyledComponents/StyledChip';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function OrganizationSearchCard({organization}: {organization: any}) {
  const {id, name, plan, payment_status, created_at, updated_at} = organization;

  return (
    <Box p={2} pb={1} pt={1}>
      <Grid container>
        <StyledTitleLink name={name} id={id} linkTo={`/organizations/${id}`} />
      </Grid>
      <Grid container>
        <Grid xs={4}>
          <Typography variant="caption" color="text.secondary" style={{marginLeft: '6px'}}>
            Plan
          </Typography>
          <Typography variant="body1" gutterBottom>
            <StyledChip size="small" label={plan.toString() || ''} color={getAccentColor(plan)} />
          </Typography>
        </Grid>
        <Grid xs={4}>
          <Typography variant="caption" color="text.secondary">
            Payment Status
          </Typography>
          <Typography variant="body1" gutterBottom>
            <StyledChip
              size="small"
              label={payment_status.toString() || ''}
              color={getAccentColor(payment_status)}
            />
          </Typography>
        </Grid>
        <Grid xs={3}>
          <Typography variant="caption" color="text.secondary">
            Dates (Created/Updated)
          </Typography>
          <Typography variant="body1" gutterBottom>
            {created_at}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {updated_at}
          </Typography>
        </Grid>
      </Grid>

      <Divider />
    </Box>
  );
}

export default OrganizationSearchCard;
