import { createReducer } from "@reduxjs/toolkit";
import * as actions from "../actions/userActions";
import { type User } from "../types";

interface UserState {
  loading: boolean;
  items: Record<string, User | null>;
  searchError: string;
}

const initalState = {
  loading: false,
  items: {},
  searchError: "",
} as UserState;

const usersReducer = createReducer(initalState, (builder) => {
  builder
    .addCase(actions.getUserInfoRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.getUserInfoFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.getUserInfoSuccess, (state, action) => {
      state.loading = false;
      state.items[action.payload.id] = action.payload;
    })
    .addCase(actions.searchUsersByEmailFailure, (state, action) => {
      state.searchError = action.payload.message;
    });
});

export default usersReducer;
