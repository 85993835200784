import {Box, Divider, Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function ProjectSearchCard({project}: {project: any}) {
  const {id, name, organization_id, created_at, updated_at, environment} = project;
  return (
    <Box p={2} pb={1} pt={1}>
      <Grid container>
        <StyledTitleLink name={name} id={id} linkTo={`/projects/${id}`} />
      </Grid>
      <Grid container>
        <Grid xs={4}>
          <Typography variant="caption" color="text.secondary">
            Environment
          </Typography>
          <Typography variant="body1" gutterBottom>
            {environment}
          </Typography>
        </Grid>
        <Grid container direction="column" xs={4}>
          <Typography variant="caption" color="text.secondary" pt="4px">
            Org ID
          </Typography>
          <Typography
            component={Link}
            to={`/organizations/${organization_id}`}
            variant="body1"
            gutterBottom
            color="primary"
          >
            {organization_id}
          </Typography>
        </Grid>
        <Grid xs={3}>
          <Typography variant="caption" color="text.secondary">
            Dates (Created/Updated)
          </Typography>
          <Typography variant="body1" gutterBottom>
            {created_at}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {updated_at}
          </Typography>
        </Grid>
      </Grid>

      <Divider />
    </Box>
  );
}

export default ProjectSearchCard;
