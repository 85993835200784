import {zodResolver} from '@hookform/resolvers/zod';
import {Box, Button, Typography} from '@mui/material';
import TextInput from '@pinecone-experience/timber/Inputs/TextInput';
import BaseModal from '@pinecone-experience/timber/Modals/BaseModal';
import ModalHeader from '@pinecone-experience/timber/Modals/ModalHeader';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {patchProjectOrganization} from '../actions/projectActions';
import {useDashboardApi} from '../hooks/use-api';
import {type Project} from '../types';

const styles = {
  container: {minWidth: 400},
};

const schema = z.object({
  targetOrganizationId: z.string(),
});

interface MoveProjectPopupProps {
  project: Project;
  open: boolean;
  handleClose: () => void;
}

function MoveProjectPopup({project, open, handleClose}: MoveProjectPopupProps) {
  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    projectId: string;
    targetOrganizationId: string;
  }>();

  const {handleSubmit, control} = useForm({
    mode: 'onChange',
    defaultValues: {
      targetOrganizationId: '',
    },
    resolver: zodResolver(schema),
  });

  const handleFormSubmit = (formData: z.infer<typeof schema>) => {
    setQueryData({
      action: patchProjectOrganization,
      data: {
        organizationId: project.organization_id,
        projectId: project.id,
        targetOrganizationId: formData.targetOrganizationId,
      },
    });
    handleClose();
  };

  return (
    <BaseModal open={open} onClose={handleClose} sx={styles.container}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <ModalHeader
          title="Move project"
          subtitle={`Change the project's organization`}
          close={handleClose}
        />
        <Box height={16} />
        <TextInput
          label="Enter target organization:"
          placeholder="Organization ID"
          name="targetOrganizationId"
          fullWidth
          control={control}
        />
        <Typography>
          Moving project <strong>{project.name}</strong> from{' '}
          <strong>{project.organization_id}</strong> to target.
        </Typography>
        <Box height={16} />
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" color="primary" autoFocus>
          Confirm
        </Button>
      </form>
    </BaseModal>
  );
}

export default MoveProjectPopup;
