import {type TypedUseSelectorHook, useSelector as rawUseSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {type RootState} from '../app/store';
import {type Organization} from '../types';

const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

export const useSelectedOrganizationId = () => {
  const params = useParams();
  return params.orgId;
};

export const useSelectedIndexName = () => {
  const params = useParams();
  return params.indexName;
};

export const useIndex = (projectId: string, indexName: string) => {
  return useSelector((state) =>
    state.projects.indexes[projectId]?.find((index) => index.index_name === indexName),
  );
};

export const useSelectedProjectId = () => {
  const params = useParams();
  return params.projectId;
};

export const useSelectedUserId = () => {
  const params = useParams();
  return params.userId;
};

export const useOrganization = (organizationId: string) => {
  return useSelector((state) => {
    return state.organizations.items[organizationId];
  });
};

export const useUserRolesByOrgId = (organizationId: string) => {
  return useSelector((state) => {
    return state.organizations.userRoles.filter((roleInfo) => roleInfo.orgId === organizationId);
  });
};

export const useProject = (projectId: string) => {
  return useSelector((state) => {
    return state.projects.items[projectId];
  });
};

export const useUserRolesByProjectId = (projectId: string) => {
  return useSelector((state) => {
    return state.projects.userRoles.filter((roleInfo) => roleInfo.projectId === projectId);
  });
};

export const useProjectsByOrgId = (orgId: string) => {
  return useSelector((state) => {
    return Object.values(state.projects.items).filter(
      (project) => project?.organization_id === orgId,
    );
  });
};

export const useUsersByOrgId = (orgId: string) => {
  return useSelector((state) => {
    return state.organizations.userRoles
      .filter((userRole) => userRole.orgId === orgId)
      .map((userRole) => state.users.items[userRole.userId])
      .filter((user) => user !== undefined);
  });
};

export const useProjectsByUserId = (userId: string) => {
  return useSelector((state) => {
    return Object.values(state.projects.items).filter((project) => {
      return state.projects.userRoles.find(
        (role) => role.projectId === project?.id && role.userId === userId,
      );
    });
  });
};

export const useOrganizationsByUserId = (userId: string) => {
  return useSelector((state) => {
    return Object.values(state.organizations.items).filter((org) => {
      return state.organizations.userRoles.find(
        (role) => role.orgId === org?.id && role.userId === userId,
      );
    }) as Organization[];
  });
};

export const useUserOrganizationRole = (userId: string, orgId: string) => {
  return useSelector((state) => {
    return state.organizations.userRoles.find(
      (record) => record.userId === userId && record.orgId === orgId,
    )?.role;
  });
};

export const useUser = (userId: string) => {
  return useSelector((state) => {
    return state.users.items[userId];
  });
};

export const useSearchError = () => {
  return useSelector((state) => state.users.searchError);
};

export const useDatabases = (projectId: string) => {
  return useSelector((state) => state.projects.indexes[projectId]);
};

export const useCollections = (projectId: string) => {
  return useSelector((state) => state.projects.collections[projectId]);
};

export const useProjectIsLoading = () => {
  return useSelector((state) => state.projects.loading);
};

export const useIndexesAndCollectionsIsLoading = () => {
  return useSelector((state) => state.projects.servicesLoading);
};

export const useProjectQuotaLimits = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaLimits.projects?.[projectId]?.quotaLimits || {};
  });
};

export const useProjectQuotaLimitsIsLoading = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaLimits.projects?.[projectId]?.loading;
  });
};

export const useProjectQuotaUtilization = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaUtilization.projects?.[projectId]?.quotaUtilization || {};
  });
};

export const useProjectQuotaUtilizationIsLoading = (projectId: string) => {
  return useSelector((state) => {
    return state.quotaUtilization?.projects?.[projectId]?.loading;
  });
};

export const useNotifications = () => {
  return useSelector((state) => state.notify.notifications);
};

export const useActiveNotification = () => {
  return useNotifications()[0];
};
