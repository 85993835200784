import SegmentIcon from '@mui/icons-material/Segment';
import {IconButton} from '@mui/material';
import {type Database} from '../../types';

function DatadogAPMButton(database: Database) {
  const handleClick = () => {
    const isServerless = database.capacity_mode === 'serverless';
    let url;

    if (isServerless) {
      url = `https://preprod.datadoghq.com/apm/traces?query=%40http.host%3A${database.host}`;
    } else if (database.rawCrd) {
      url = `https://app.datadoghq.com/apm/traces?query=%40http.host%3A${database.host}`;
    } else {
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={handleClick}>
      <SegmentIcon fontSize="inherit" />
    </IconButton>
  );
}

export default DatadogAPMButton;
