import { Close } from "@carbon/icons-react";
import { Box, Button, IconButton, InputBase, Paper } from "@mui/material";
import { useState } from "react";

const styles = {
  container: {
    display: "flex",
    my: 2,
    height: 50,
  },
  searchContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "550px",
    minWidth: "300px",
    pl: 2,
  },
  input: {
    width: "100%",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    mr: 0.5,
  },
  button: {
    ml: 2,
    px: 4,
  }
};

type SearchBarProps = {
  search: string;
  placeholder?: string;
  onSearch: (query: string) => void;
};

function StyledSearchBar({
  search,
  placeholder = "Search ...",
  onSearch,
}: SearchBarProps) {
  const [searchString, setSearchString] = useState(search);

  const handleSearch = () => {
    onSearch(searchString);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.searchContainer}>
        <InputBase
          sx={styles.input}
          value={searchString}
          onChange={(event) => {
            setSearchString(event.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
        />

        <Box sx={styles.icons}>
          {searchString.length >= 3 && (
            <IconButton onClick={() => {              
              setSearchString('');
              handleSearch();
            }}>
              <Close />
            </IconButton>
          )}
        </Box>
      </Paper>
      <Button sx={styles.button} variant="contained" disableElevation onClick={handleSearch}>
        Search
      </Button>
    </Box>
  );
}
export default StyledSearchBar;