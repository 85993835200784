import {Box, Divider, Grid, Link, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function UserSearchCard({user}: {user: any}) {
  const navigate = useNavigate();
  const {id, firebase_id, email, name, created_at, updated_at} = user;
  const onClick = () => {
    navigate(`/users/${encodeURIComponent(id)}`);
  };
  return (
    <Box p={2} pb={1} pt={1}>
      <Grid container>
        <StyledTitleLink name={name} id={id} linkTo={`/users/${id}`} />
      </Grid>
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="caption" color="text.secondary">
            Auth0 id
          </Typography>
          <Link style={{cursor: 'pointer'}} onClick={onClick}>
            <Typography variant="body1" gutterBottom>
              {firebase_id}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption" color="text.secondary">
            Email
          </Typography>
          <Typography variant="body1" gutterBottom>
            {email}
          </Typography>
        </Grid>
        <Grid xs={3}>
          <Typography variant="caption" color="text.secondary">
            Dates (Created/Updated)
          </Typography>
          <Typography variant="body1" gutterBottom>
            {created_at}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {updated_at}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}

export default UserSearchCard;
