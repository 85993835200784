import {LinearProgress, Paper, Typography} from '@mui/material';
import {type QuotaKeys} from '@pinecone-experience/commons/quotas';
import Table from '@pinecone-experience/timber/Table';
import Tooltip from '@pinecone-experience/timber/Tooltip';
import {
  useProjectQuotaLimits,
  useProjectQuotaLimitsIsLoading,
  useProjectQuotaUtilization,
  useProjectQuotaUtilizationIsLoading,
} from '../../hooks/selectors';

const getColor = (percentage: number) => {
  if (percentage >= 100) return 'error';
  if (percentage >= 80) return 'warning';
  return 'primary';
};

function ProjectQuotasCard({projectId}: {projectId: string}) {
  const quotaLimits = useProjectQuotaLimits(projectId);
  const quotaUtilization = useProjectQuotaUtilization(projectId);
  const quotaLimitsIsLoading = useProjectQuotaLimitsIsLoading(projectId);
  const quotaUtilizationIsLoading = useProjectQuotaUtilizationIsLoading(projectId);

  const quotaCellRenderer = (row: number, column: number) => {
    const quotaKey = Object.keys(quotaLimits)[row] as QuotaKeys;
    const limit = quotaLimits[quotaKey].value;
    const utilization = quotaUtilization[quotaKey];
    switch (column) {
      case 0:
        return quotaKey;
      case 1:
        return Number(limit).toLocaleString();
      case 2:
        if (utilization !== undefined) return Number(utilization).toLocaleString();
        return 'N/A';
      case 3:
        if (utilization !== undefined) {
          const percentage = Math.min((utilization / limit) * 100, 100);
          return (
            <Tooltip title={`${utilization} / ${limit} --> ${percentage}%`}>
              <LinearProgress
                variant="determinate"
                color={getColor(percentage)}
                value={percentage}
                sx={{height: 8}}
              />
            </Tooltip>
          );
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <Paper
      sx={{
        p: 2,
      }}
    >
      <Typography variant="h2" gutterBottom>
        Quotas
      </Typography>
      <Table
        isLoading={quotaLimitsIsLoading || quotaUtilizationIsLoading}
        numRows={Object.keys(quotaLimits).length}
        columns={[
          {
            name: 'Quota Key',
            dataType: 'string' as const,
          },
          {
            name: 'Limit',
            dataType: 'number' as const,
          },
          {
            name: 'Utilization',
            dataType: 'number' as const,
            info: `Utilization is currently only available on us-east-1 region. Please don't be alarmed if this value is 0.`,
          },
          {
            name: 'Utilization Progress',
            dataType: 'actions' as const,
          },
        ]}
        cellRenderer={quotaCellRenderer}
        pagination={{
          labelled: false,
          sizeControl: true,
        }}
        itemLabel={['quota', 'quotas']}
      />
    </Paper>
  );
}

export default ProjectQuotasCard;
