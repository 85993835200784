import {IconButton} from '@mui/material';
import StripeIcon from '../../assets/stripe_icon_white.png';
import {type Organization} from '../../types';

function StripeButton(organization: Organization) {
  const handleClick = () => {
    const url = `https://dashboard.stripe.com/customers/${organization.stripe_id}`;
    window.open(url, '_blank');
  };

  return organization.stripe_id ? (
    <IconButton onClick={handleClick}>
      <img src={StripeIcon} alt="Stripe" width={37} height={37} />
    </IconButton>
  ) : (
    <img
      src={StripeIcon}
      alt="ChargeDesk"
      width={37}
      height={37}
      style={{opacity: 0.5, cursor: 'not-allowed'}}
    />
  );
}

export default StripeButton;
