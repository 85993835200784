import {Box, Divider, Paper, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {
  useCollections,
  useDatabases,
  useIndexesAndCollectionsIsLoading,
  useProject,
  useProjectIsLoading,
  useSelectedProjectId,
} from '../../hooks/selectors';
import CollectionCard from '../Cards/Collection/CollectionCard';
import DatabaseCardSmall from '../Cards/Database/DatabaseCardSmall';
import ProjectCardLarge from '../Cards/Project/ProjectCardLarge';
import ProjectQuotasCard from '../Quotas/ProjectQuotasCard';
import TitleBox from '../TitleBox';

function ProjectDetailPage() {
  const projectId = useSelectedProjectId();
  const project = useProject(projectId || '');
  const databases = useDatabases(projectId || '') || [];
  const collections = useCollections(projectId || '') || [];
  const projectIsLoading = useProjectIsLoading();
  const resourcesIsLoading = useIndexesAndCollectionsIsLoading();

  return (
    <Box mb={8}>
      <TitleBox pageTitle="Project Details" />
      <LoadingContainer loading={projectIsLoading}>
        <Paper sx={{p: 2}}>{project && <ProjectCardLarge {...project} />}</Paper>
      </LoadingContainer>

      <LoadingContainer loading={resourcesIsLoading}>
        <Paper sx={{p: 2, mt: 10}}>
          {databases.length > 0 ? (
            <>
              <Typography variant="h2">Indexes</Typography>
              {databases.map((database) => (
                <Box key={database.id}>
                  <Divider sx={{my: 2}} />
                  <DatabaseCardSmall {...database} />
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body1">No active indexes in this project.</Typography>
          )}
        </Paper>
      </LoadingContainer>

      <LoadingContainer loading={resourcesIsLoading}>
        <Paper sx={{p: 2, mt: 10}}>
          {collections.length > 0 ? (
            <>
              <Typography variant="h2">Collections</Typography>
              {collections.map((collection) => (
                <Box key={collection.id}>
                  <Divider sx={{my: 2}} />
                  <CollectionCard {...collection} />
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="body1">No active collections in this project.</Typography>
          )}
        </Paper>
      </LoadingContainer>

      <Box height={20} />
      <ProjectQuotasCard projectId={projectId || ''} />
    </Box>
  );
}

export default ProjectDetailPage;
