import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Divider, Typography} from '@mui/material';
import {useState} from 'react';
import {useUsersByOrgId} from '../../../hooks/selectors';
import {type Organization, type User} from '../../../types';
import StyledUsersTable from '../../StyledComponents/StyledUsersTable';

function OrganizationCardUserLarge(organization: Organization) {
  const users = useUsersByOrgId(organization.id).sort((a, b) => {
    if (!a || !b) return 0;
    if (a.role === 'owner' && b.role !== 'owner') return -1;
    if (a.role !== 'owner' && b.role === 'owner') return 1;
    return 0;
  });
  const [expanded, setExpanded] = useState(true);

  const handleAccordionChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return users.length > 0 ? (
    <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="members-content"
        id="members-header"
      >
        <Typography variant="h2">Members</Typography>
        <Divider sx={{my: 2}} />
      </AccordionSummary>
      <AccordionDetails>
        <StyledUsersTable users={users.filter((user): user is User => user !== null)} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <Typography>No users</Typography>
  );
}

export default OrganizationCardUserLarge;
