import {Box, alpha} from '@mui/material';
import Chip, {type ChipProps} from '@mui/material/Chip';
import type React from 'react';
import {getShade, getTint} from '../../styles/utils';

const styles = {
  chip: (container: string, onContainer: string, selected: boolean, size: ChipProps['size']) => ({
    backgroundColor: selected ? container : 'background.paper',
    color: onContainer,
    border: size === 'small' ? 0 : 1,
    borderColor: selected ? onContainer : 'divider',
    borderRadius: size === 'small' ? 1 : 2,
    '&:hover': {
      backgroundColor: selected ? alpha(container, 0.4) : alpha(container, 0.4),
    },
    fontWeight: 'bold',
    fontFamily: 'monospace',
    m: 0.25,
  }),
};

type StyledChipProps = {
  label: string;
  color?: string;
  size?: ChipProps['size'];
  selected?: boolean;
  icon?: React.ReactElement;
  onClick?: () => void;
  sx?: ChipProps['sx'];
};
const StyledChip: React.FC<StyledChipProps> = ({
  label,
  color = '#555',
  size = 'medium',
  selected = true,
  icon,
  onClick,
  sx,
}) => {
  const container = alpha(getShade(color, 50), 0.6);
  const onContainer = getTint(color, 50);
  const chipStyles = styles.chip(container, onContainer, selected, size);
  return (
    <Chip
      sx={{
        ...chipStyles,
        ...sx,
      }}
      icon={<Box sx={{color: onContainer, display: 'grid', placeItems: 'center'}}>{icon}</Box>}
      size={size}
      label={label}
      onClick={onClick}
    />
  );
};

export default StyledChip;
