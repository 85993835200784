import {type Reducer, combineReducers} from 'redux';
import notify from './notifyReducer';
import organizations from './organizationsReducer';
import projects from './projectsReducer';
import quotaLimits from './quotaLimitsReducer';
import quotaUtilization from './quotaUtilizationReducer';
import users from './usersReducer';

const createReducer = (router: Reducer) => {
  return combineReducers({
    router,
    organizations,
    projects,
    users,
    quotaLimits,
    quotaUtilization,
    notify,
  });
};

export default createReducer;
