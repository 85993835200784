import Alert, {type AlertProps} from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import type React from 'react';
import {type ReactElement} from 'react';

type StyledAlertProps = {
  title?: string;
  description?: string | ReactElement;
  variant?: AlertProps['variant'];
  severity?: AlertProps['severity'];
  id?: string;
  action?: AlertProps['action'];
  sx?: AlertProps['sx'];
};
const StyledAlert: React.FC<StyledAlertProps> = ({
  title,
  description,
  variant,
  severity = 'info',
  id,
  action,
  sx = {my: 2},
}) => {
  return (
    <Alert id={id} variant={variant} severity={severity} action={action} sx={sx}>
      {title && <AlertTitle sx={{fontWeight: 'bold'}}>{title}</AlertTitle>}
      {description}
    </Alert>
  );
};

export default StyledAlert;
