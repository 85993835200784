import { Box } from "@mui/material";
import TitleBox from "../TitleBox";

export default function HomePage() {
  return (
    <Box>
      <TitleBox pageTitle="Pinecone Admin Dashboard" hideBreadcrumbs/>
    </Box>
  );
}
