import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material';
import {AdminTheme} from '@pinecone-experience/theme';
import {Route, unstable_HistoryRouter as Router, Routes} from 'react-router-dom';
import {history} from '../app/store';
import MainScreen from './MainScreen';
import HomePage from './Pages/HomePage';
import IndexDetailsPage from './Pages/IndexDetailPage';
import OrganizationDetailPage from './Pages/OrganizationDetailPage';
import OrganizationSearchPage from './Pages/OrganizationSearchPage';
import ProjectDetailPage from './Pages/ProjectDetailPage';
import ProjectSearchPage from './Pages/ProjectSearchPage';
import ProjectWrapper from './Pages/ProjectWrapper';
import UserDetailPage from './Pages/UserDetailPage';
import UserSearchPage from './Pages/UserSearchPage';

function MainApp() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AdminTheme}>
        <CssBaseline />
        <Router history={history}>
          <Routes>
            <Route path="/" element={<MainScreen />}>
              <Route path="" element={<HomePage />} />
              <Route path="organizations/">
                <Route path="" element={<OrganizationSearchPage />} />
                <Route path=":orgId/" element={<OrganizationDetailPage />} />
              </Route>
              <Route path="projects/" element={<ProjectWrapper />}>
                <Route path="" element={<ProjectSearchPage />} />
                <Route path=":projectId/">
                  <Route path="" element={<ProjectDetailPage />} />
                  <Route path=":indexName/" element={<IndexDetailsPage />} />
                </Route>
              </Route>
              <Route path="users/">
                <Route path="" element={<UserSearchPage />} />
                <Route path=":userId/" element={<UserDetailPage />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default MainApp;
