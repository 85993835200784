import {IconButton} from '@mui/material';
import OrbIcon from '../../assets/orb_icon_white.png';
import {type Organization} from '../../types';

function OrbButton(organization: Organization) {
  const handleClick = () => {
    const url = `https://app.withorb.com/customers/${organization.orb_customer_id}`;
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={handleClick}>
      <img src={OrbIcon} alt="Orb" width={37} height={37} />
    </IconButton>
  );
}

export default OrbButton;
