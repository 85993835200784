import {type Dispatch, createAction} from '@reduxjs/toolkit';
import {push} from 'redux-first-history';
import DashboardApi from '../api/DashboardApi';
import {type User} from '../types';
// eslint-disable-next-line import/no-cycle
import {getOrganizationInfoSuccess} from './organizationActions';
// eslint-disable-next-line import/no-cycle
import {getProjectInfoSuccess} from './projectActions';

export const getUserInfoRequest = createAction<undefined>('GET_USER_INFO_REQUEST');
export const getUserInfoSuccess = createAction<User>('GET_USER_INFO_SUCCESS');
export const getUserInfoFailure = createAction<Error>('GET_USER_INFO_FAILURE');

export function getUserInfo(token: string, data: {userId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getUserInfoRequest());
    return new DashboardApi(token)
      .getUserInfo(data.userId)
      .then((res) => {
        dispatch(getUserInfoSuccess(res.user));
        res.orgs.forEach((org) => {
          dispatch(
            getOrganizationInfoSuccess({
              org,
              users: [
                {
                  ...res.user,
                  role: org.role,
                },
              ],
            }),
          );
        });
        res.projects.forEach((project) => {
          dispatch(
            getProjectInfoSuccess({
              project,
              users: [
                {
                  ...res.user,
                  role: project.role,
                },
              ],
            }),
          );
        });
      })
      .catch((err) => {
        dispatch(getUserInfoFailure(err));
      });
  };
}

export const searchUsersByEmailRequest = createAction<undefined>('SEARCH_USERS_BY_EMAIL_REQUEST');
export const searchUsersByEmailSuccess = createAction<User>('SEARCH_USERS_BY_EMAIL_SUCCESS');
export const searchUsersByEmailFailure = createAction<Error>('SEARCH_USERS_BY_EMAIL_FAILURE');

export function searchUsersByEmail(token: string, data: {email: string}) {
  return (dispatch: Dispatch) => {
    dispatch(searchUsersByEmailRequest());
    return new DashboardApi(token)
      .searchUsersByEmail(data.email)
      .then((res) => {
        if (res.users.length !== 1) {
          throw new Error(`${res.users.length} users found with email ${data.email}`);
        }
        dispatch(searchUsersByEmailSuccess(res.users[0]));
        dispatch(push(`/users/${res.users[0].id}`));
      })
      .catch((err) => {
        dispatch(searchUsersByEmailFailure(err));
      });
  };
}
