import {useAuth0} from '@auth0/auth0-react';
import {Avatar, Box, Button, Popover, Typography} from '@mui/material';
import {useState} from 'react';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 2,
  },
  avatar: {
    backgroundColor: 'secondary.main',
  },
};

function UserMenu() {
  const {user, logout} = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = () => {
    logout();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={styles.root}>
      <Avatar sx={styles.avatar} onClick={handleClick}>
        {' '}
        {user?.name && user.name.split(' ').map((word) => (word[0] ? word[0].toUpperCase() : ''))}
      </Avatar>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{marginLeft: 7}}
      >
        <Box sx={{p: 2}}>
          <Typography variant="h6">Settings</Typography>
          <Button variant="outlined" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
export default UserMenu;
