import {configureStore} from '@reduxjs/toolkit';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from 'redux-first-history';
import createReducer from '../reducers/rootReducer';

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const middleware = [routerMiddleware];

export const store = configureStore({
  reducer: createReducer(routerReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware as any),
});

export function setupStore(preloadedState?: any) {
  return configureStore({
    reducer: createReducer(routerReducer),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware as any),
    preloadedState,
  });
}

export type AppStore = ReturnType<typeof setupStore>;
export const history = createReduxHistory(store) as any;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
