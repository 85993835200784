import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {useState} from 'react';
import {useProjectsByOrgId} from '../../../hooks/selectors';
import {type Organization} from '../../../types';
import StyledDateFormatting from '../../StyledComponents/StyledDateFormatting';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

function OrganizationCardProjectTable(organization: Organization) {
  const {id} = organization;
  const projects = useProjectsByOrgId(id);
  const [expanded, setExpanded] = useState(true);

  const handleAccordionChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Box>
      {projects.length > 0 ? (
        <Accordion expanded={expanded} onChange={handleAccordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="organization-content"
            id="organization-header"
          >
            <Typography variant="h2">Projects</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {projects.map((project) => {
              const projId = project?.id;
              const name = project?.name;
              const serverlessQuota = project?.index_quota;
              const podQuota = project?.quota;
              const active = project?.active;
              const created_at = project?.created_at;
              const updated_at = project?.updated_at;
              const environment = project?.environment;
              return (
                <Box key={projId}>
                  <Divider sx={{mb: 2}} />
                  <Grid xs={12} container spacing={2} alignItems="center">
                    <Grid xs={10.5}>
                      <StyledTitleLink
                        name={name as string}
                        id={projId as string}
                        linkTo={`/projects/${projId}`}
                      />
                    </Grid>
                    <Grid xs={1}>
                      <Typography variant="h3" sx={{color: active ? 'green' : 'red'}}>
                        {active ? 'Active' : 'Inactive'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container spacing={2} alignItems="center">
                    <Grid xs={4}>
                      <Box sx={{pl: 4}}>
                        <Typography variant="body1">Pod quota: {podQuota}</Typography>
                        <Typography variant="body1">Serverless quota: {serverlessQuota}</Typography>
                      </Box>
                    </Grid>
                    <Grid xs={4}>
                      <Box sx={{pl: 4}}>
                        <Typography variant="body1">Environment: {environment}</Typography>
                      </Box>
                    </Grid>
                    <Grid xs={4}>
                      <Box sx={{pl: 4, mb: 1.5}}>
                        <StyledDateFormatting created_at={created_at} updated_at={updated_at} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography variant="h2">No projects</Typography>
      )}
    </Box>
  );
}

export default OrganizationCardProjectTable;
