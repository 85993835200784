import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {patchUserOrgRole} from '../../../actions/organizationActions';
import {useProjectsByOrgId, useUserOrganizationRole} from '../../../hooks/selectors';
import {useDashboardApi} from '../../../hooks/use-api';
import {PaymentPlanColors, PaymentStatusColors, UserRoleColors} from '../../../styles/colors';
import {type Organization, type Role, type User} from '../../../types';
import Popup from '../../ChangeUserRolePopup';
import StyledChip from '../../StyledComponents/StyledChip';
import InfoBox from '../../StyledComponents/StyledInfoBox';
import StyledTitleLink from '../../StyledComponents/StyledTitleLink';

interface UserOrganizationRowProps {
  organization: Organization;
  user: User;
  isSingleOrg: boolean;
}

function UserOrganizationCard({user, organization, isSingleOrg}: UserOrganizationRowProps) {
  const role = useUserOrganizationRole(user.id, organization.id);
  const Projects = useProjectsByOrgId(organization.id);

  const [openPopup, setPopupOpen] = useState(false);

  const {setQueryData} = useDashboardApi<{
    organizationId: string;
    firebaseId: string;
    newRole: Role;
    userId: string;
  }>();

  const handleRoleChange = (newRole: Role) => {
    setQueryData({
      action: patchUserOrgRole,
      data: {
        firebaseId: user.firebase_id,
        organizationId: organization.id,
        newRole,
        userId: user.id,
      },
    });
    setPopupOpen(false);
  };

  const handleClickPopupOpen = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const [expanded, setExpanded] = useState(isSingleOrg);

  const handleAccordionChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{mb: 2}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="organization-content"
        id="organization-header"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <StyledTitleLink
              name={organization.name}
              id={organization.id}
              linkTo={`/organizations/${organization.id}`}
              onClick={handleButtonClick}
            />
          </Grid>
          <Grid item xs={2}>
            <InfoBox label="Plan">
              <StyledChip
                size="small"
                label={organization.plan === 'free' ? 'starter' : organization.plan}
                color={PaymentPlanColors[organization.plan]}
              />
            </InfoBox>
          </Grid>
          <Grid item xs={2}>
            <InfoBox label="Payment Status">
              <StyledChip
                size="small"
                label={organization.payment_status}
                color={PaymentStatusColors[organization.payment_status]}
              />
            </InfoBox>
          </Grid>
          <Grid item xs={3}>
            <InfoBox label="Role">
              <Box display="flex" alignItems="center">
                <StyledChip
                  size="small"
                  label={role === 'owner' ? 'Owner' : 'Member'}
                  color={UserRoleColors[role as keyof typeof UserRoleColors]}
                />
                <Tooltip title="Change user role">
                  <IconButton
                    onClick={(e) => {
                      handleButtonClick(e);
                      handleClickPopupOpen();
                    }}
                    size="small"
                    color="primary"
                    sx={{ml: 1}}
                  >
                    <ManageAccountsIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </InfoBox>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h6" sx={{mt: 3, mb: 2}}>
          Projects
        </Typography>
        {Projects.length === 0 ? (
          <Typography>No projects found</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Project ID</TableCell>
                  <TableCell>Dates (Created/Updated)</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Projects.map((project) => (
                  <TableRow
                    key={project?.id}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                      },
                    }}
                  >
                    <TableCell>
                      <Typography
                        component={Link}
                        to={`/projects/${project?.id}`}
                        sx={{
                          textDecoration: 'none',
                          color: 'primary.main',
                        }}
                      >
                        {project?.name}
                      </Typography>
                    </TableCell>
                    <TableCell>{project?.id}</TableCell>
                    <TableCell>
                      <div> {project?.created_at}</div>
                      <div> {project?.updated_at}</div>
                    </TableCell>
                    <TableCell>
                      <StyledChip
                        size="small"
                        label={project?.role === 'owner' ? 'Owner' : 'User'}
                        color={UserRoleColors[project?.role as keyof typeof UserRoleColors]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
      <Popup
        open={openPopup}
        handleClose={handleClose}
        onRoleChange={handleRoleChange}
        user={user}
        organization={organization}
        role={role}
      />
    </Accordion>
  );
}

export default UserOrganizationCard;
