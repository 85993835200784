import {type QuotaKeys} from '@pinecone-experience/commons/quotas';
import {createReducer, isAnyOf} from '@reduxjs/toolkit';
import {
  getProjectQuotaUtilizationFailure,
  getProjectQuotaUtilizationRequest,
  getProjectQuotaUtilizationSuccess,
} from '../actions/quotaActions';

interface ProjectState {
  loading: boolean;
  quotaUtilization: Record<QuotaKeys, number>;
}

const initialProjectState = {
  loading: true,
  quotaUtilization: {},
} as ProjectState;

interface QuotaUtilizationState {
  projects: Record<string, ProjectState>;
}

const initialState = {
  projects: {},
} as QuotaUtilizationState;

const projectReducer = createReducer(initialProjectState, (builder) => {
  builder
    .addCase(getProjectQuotaUtilizationRequest, (state) => {
      state.loading = true;
    })
    .addCase(getProjectQuotaUtilizationSuccess, (state, action) => {
      state.quotaUtilization = action.payload.quotaUtilization;
      state.loading = false;
    })
    .addCase(getProjectQuotaUtilizationFailure, (state) => {
      state.loading = false;
    });
});

const quotaUtilizationReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(
    isAnyOf(
      getProjectQuotaUtilizationFailure,
      getProjectQuotaUtilizationRequest,
      getProjectQuotaUtilizationSuccess,
    ),
    (state, action) => {
      state.projects[action.payload.projectId] = projectReducer(
        state.projects[action.payload.projectId],
        action,
      );
    },
  );
});

export default quotaUtilizationReducer;
