import {Box, Paper} from '@mui/material';
import {useEffect} from 'react';
import {getOrganizationInfo} from '../../actions/organizationActions';
import {useOrganization, useSelectedOrganizationId} from '../../hooks/selectors';
import {useDashboardApi} from '../../hooks/use-api';
import OrganizationCardLarge from '../Cards/Organization/OrganizationCardLarge';
import OrganizationCardProjectList from '../Cards/Organization/OrganizationCardProjectList';
import OrganizationCardUser from '../Cards/Organization/OrganizationCardUser';
import TitleBox from '../TitleBox';

function OrganizationDetailPage() {
  const organizationId = useSelectedOrganizationId();
  const {setQueryData} = useDashboardApi<{organizationId: string}>();
  useEffect(() => {
    if (organizationId) {
      setQueryData({
        action: getOrganizationInfo,
        data: {organizationId},
      });
    }
  }, [organizationId, setQueryData]);
  const organization = useOrganization(organizationId || '');
  return (
    <Box mb={8}>
      <TitleBox pageTitle="Organization Details" />
      <Paper sx={{p: 2}}>{organization && <OrganizationCardLarge {...organization} />}</Paper>
      <br />
      <Paper sx={{p: 2}}>{organization && <OrganizationCardProjectList {...organization} />}</Paper>
      <br />
      <Paper sx={{p: 2}}>{organization && <OrganizationCardUser {...organization} />}</Paper>
    </Box>
  );
}

export default OrganizationDetailPage;
