import { Box, Paper } from "@mui/material";
import {
  useIndex,
  useSelectedIndexName,
  useSelectedProjectId,
} from "../../hooks/selectors";
import DatabaseCardLarge from "../Cards/Database/DatabaseCardLarge";
import TitleBox from "../TitleBox";

function IndexDetailsPage() {
  const projectId = useSelectedProjectId();
  const indexName = useSelectedIndexName();
  const index = useIndex(projectId || "", indexName || "")
  return (
    <Box mb={8}>
      <TitleBox pageTitle="Index Details" />
      <Paper sx={{ p: 2 }}>{index != null && <DatabaseCardLarge {...index}/>}</Paper>
    </Box>
  );
}

export default IndexDetailsPage;
