import { ChevronRight } from '@carbon/icons-react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const styles = {
  container: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'background.default',
    display: 'flex',
    flexDirection: 'column',
    py: 4,
    zIndex: 100,
  },
};
function LinkRouter(props: any) {
  return <Link {...props} component={RouterLink} />;
}
type TitleBoxProps = {
  pageTitle: string;
  hideBreadcrumbs?: boolean;
};

function TitleBox({ pageTitle, hideBreadcrumbs = false }: TitleBoxProps) {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  return (
    <Box sx={styles.container}>
      {!hideBreadcrumbs && (
        <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight />}>
          <LinkRouter underline="hover" color="inherit" to="/">
            home
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <Typography color="inherit" key={to}>
                {decodeURIComponent(value)}
              </Typography>
            ) : (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                {value}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      )}
      <Typography variant="h1">{pageTitle}</Typography>
    </Box>
  );
}
export default TitleBox;
