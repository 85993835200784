import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type Collection, type Database, type Project, type UserMember} from '../types';
// eslint-disable-next-line import/no-cycle
import {getUserInfoSuccess} from './userActions';

export const getProjectInfoRequest = createAction<undefined>('GET_PROJECT_INFO_REQUEST');
export const getProjectInfoSuccess = createAction<{
  project: Project;
  users?: UserMember[];
}>('GET_PROJECT_INFO_SUCCESS');
export const getProjectInfoFailure = createAction<Error>('GET_PROJECT_INFO_FAILURE');

export function getProjectInfo(token: string, data: {projectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getProjectInfoRequest());
    return new DashboardApi(token)
      .getProjectInfo(data.projectId)
      .then((res) => {
        dispatch(getProjectInfoSuccess({project: res.project, users: res.users}));
        res.users.forEach((user) => {
          dispatch(getUserInfoSuccess(user));
        });
      })
      .catch((err) => {
        dispatch(getProjectInfoFailure(err));
      });
  };
}

export const getProjectServicesRequest = createAction<undefined>('GET_PROJECT_SERVICES_REQUEST');
export const getProjectServicesSuccess = createAction<{
  databases: Database[];
  collections: Collection[];
  projectId: string;
}>('GET_PROJECT_SERVICES_SUCCESS');
export const getProjectServicesFailure = createAction<Error>('GET_PROJECT_SERVICES_FAILURE');

export function getProjectServices(token: string, data: {projectId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getProjectServicesRequest());
    return new DashboardApi(token)
      .getProjectServices(data.projectId)
      .then((res) => {
        dispatch(
          getProjectServicesSuccess({
            projectId: data.projectId,
            databases: res.databases,
            collections: res.collections,
          }),
        );
      })
      .catch((err) => {
        dispatch(getProjectServicesFailure(err));
      });
  };
}
export const patchProjectOrganizationRequest = createAction<{
  organizationId: string;
  projectId: string;
  targetOrganizationId: string;
}>('PATCH_PROJECT_ORGANIZATION_REQUEST');

export const patchProjectOrganizationSuccess = createAction<{
  organizationId: string;
  projectId: string;
  targetOrganizationId: string;
}>('PATCH_PROJECT_ORGANIZATION_SUCCESS');

export const patchProjectOrganizationFailure = createAction<{
  error: Error;
  data: {organizationId: string; projectId: string; targetOrganizationId: string};
}>('PATCH_PROJECT_ORGANIZATION_FAILURE');

export function patchProjectOrganization(
  token: string,
  data: {organizationId: string; projectId: string; targetOrganizationId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      patchProjectOrganizationRequest({
        organizationId: data.organizationId,
        projectId: data.projectId,
        targetOrganizationId: data.targetOrganizationId,
      }),
    );
    return new DashboardApi(token)
      .patchProjectOrganization(data.organizationId, data.projectId, data.targetOrganizationId)
      .then(() => {
        dispatch(
          patchProjectOrganizationSuccess({
            organizationId: data.organizationId,
            projectId: data.projectId,
            targetOrganizationId: data.targetOrganizationId,
          }),
        );
      })
      .catch((err) => {
        dispatch(patchProjectOrganizationFailure(err));
      });
  };
}
