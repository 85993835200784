import { Box, Typography } from "@mui/material";
import { type ReactElement } from "react";


function InfoBox({
    label,
    text,
    children,
    size = 'medium'
  }: {
    label: string;
    text?: string | number;
    children?: ReactElement | ReactElement[];
    size?: string
  }) {
    return (
      <Box>
        <Typography variant="caption" color="text.secondary">{label}</Typography>
        <Typography variant={size === 'small' ? 'body1': "h6"} gutterBottom>{text}</Typography>
        {children}
      </Box>
    );
  }
  export default InfoBox