import Values from 'values.js';
import {AccentColorsArray} from './colors';

export const getTint = (color: string, offset: number) => {
  return new Values(color).tint(offset).hexString();
};

export const getShade = (color: string, offset: number) => {
  return new Values(color).shade(offset).hexString();
};

export const getAccentColor = (key?: string | number | boolean) => {
  if (typeof key === 'boolean') {
    return key ? AccentColorsArray[1] : AccentColorsArray[4];
  }
  if (typeof key === 'number') {
    return AccentColorsArray[Math.abs(key) % AccentColorsArray.length];
  }
  let hash = 0;
  if (!key || key.length === 0) return AccentColorsArray[0];
  for (let i = 0; i < key.length; i++) {
    const ch = key.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + ch;
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }
  return AccentColorsArray[Math.abs(hash) % AccentColorsArray.length];
};
