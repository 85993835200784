import {InfoOutlined} from '@mui/icons-material';
import {
  Box,
  Table as MuiTable,
  Paper,
  type SxProps,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  type Theme,
  Typography,
  alpha,
} from '@mui/material';
import {useEffect} from 'react';
import type * as React from 'react';
import LoadingContainer from '../LoadingContainer';
import PaginationControls from '../PaginationControls';
import {usePagination} from '../PaginationControls/hooks';
import Tooltip from '../Tooltip';

type DataType = 'string' | 'number' | 'actions';

export type TableColumn = {
  name: string;
  dataType: DataType;
  component?: React.ReactNode;
  info?: string;
  width?: number | string;
};

interface PaginationOptions {
  labelled: boolean;
  sizeControl: boolean;
}

interface TableProps {
  columns: readonly TableColumn[];
  numRows: number;
  isLoading?: boolean;
  customIdPrefix?: string;
  maxHeight?: number;
  title?: string;
  bodyCellStyleOverrides?: object;
  gutter?: number;
  selectedRow?: number;
  pagination?: PaginationOptions;
  itemLabel?: [singular: string, plural: string];
  dataKey?: string;
  emptyState?: React.ReactNode;
  cellRenderer: (row: number, col: number) => React.ReactNode;
  onClickRow?: (row: number) => void;
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '50%',
    textAlign: 'center',
    flexWrap: 'wrap',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'action.hover',
    },
  },
  table: {
    position: 'relative',
    flex: 1,
    borderCollapse: 'separate',
    height: 'max-content',
  },
  headerCell: {
    color: 'text.secondary',
    borderBottom: 0,
    borderRadius: 2,
    height: 30,
  },
  head: {
    zIndex: 1,
    backgroundColor: 'background.paper',
  },
  bodyCell: (theme: Theme, isSelected: boolean) => ({
    position: 'relative',
    borderBottom: 0,
    borderTop: 1,
    borderColor: 'divider',
    height: 50,
    p: 2,
    backgroundColor: isSelected ? alpha(theme.palette.primary.main, 0.12) : '',
  }),
  tbody: {
    overflow: 'auto',
  },
};

const cellStyles: Record<DataType, object> = {
  string: {},
  number: {
    textAlign: 'right',
  },
  actions: {
    textAlign: 'center',
  },
};

function Table({
  title,
  isLoading,
  columns,
  numRows,
  cellRenderer,
  onClickRow,
  customIdPrefix,
  maxHeight,
  bodyCellStyleOverrides = {},
  gutter = 3,
  selectedRow,
  pagination,
  itemLabel = ['row', 'rows'],
  dataKey,
  emptyState = null,
}: TableProps) {
  const prefix = customIdPrefix ? `${customIdPrefix}-` : '';
  const paginationState = usePagination(new Array(numRows), itemLabel);
  const {itemsToShow, setCurrentPage, rowsPerPage, currentPage} = paginationState;
  const rowsToShow = pagination ? itemsToShow.length : numRows;

  useEffect(() => {
    if (dataKey) {
      setCurrentPage(0);
    }
  }, [dataKey, setCurrentPage]);

  if (!numRows && !isLoading) {
    return emptyState ? (
      <Box>{emptyState}</Box>
    ) : (
      <Box sx={styles.emptyState}>
        <Box>
          <Typography color="text.primary" variant="h5" pb={2}>
            No results found
          </Typography>
          <Typography color="text.primary">Please check your filters and try again.</Typography>
        </Box>
      </Box>
    );
  }

  const getColumnStyle = (column: TableColumn): SxProps<Theme> => {
    const baseStyle: SxProps<Theme> = {
      ...styles.headerCell,
      ...cellStyles[column.dataType],
    };

    if (column.width) {
      return {
        ...baseStyle,
        width: column.width,
      };
    }

    return baseStyle;
  };

  return (
    <Box sx={[styles.root, {gap: gutter}]}>
      {pagination?.labelled ? (
        <Typography variant="body1">{`Showing ${paginationState.label}`}</Typography>
      ) : null}
      <Paper>
        {title && (
          <Typography component="h2" variant="h6" pl={2} pt={3} pb={2}>
            {title}
          </Typography>
        )}
        <LoadingContainer loading={isLoading}>
          <TableContainer
            sx={{
              maxHeight,
              minHeight: isLoading ? '100px' : 'auto',
            }}
          >
            <MuiTable sx={styles.table} aria-label="simple table">
              <TableHead sx={styles.head}>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={`${column.name}${index}`} sx={getColumnStyle(column)}>
                      {column.component ? (
                        column.component
                      ) : (
                        <Box display="inline-flex" alignItems="center">
                          <Typography fontWeight={500}>{column.name}</Typography>
                          {column.info && (
                            <Tooltip title={column.info}>
                              <InfoOutlined fontSize="small" sx={{ml: 1, mt: 1}} color="info" />
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={styles.tbody}>
                {[...Array(rowsToShow)].map((_, rowIndex) => (
                  <TableRow
                    sx={onClickRow ? styles.clickable : {}}
                    onClick={() => onClickRow && onClickRow(rowIndex)}
                    key={rowIndex}
                    id={`${prefix}${rowIndex}`}
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={`${colIndex}${rowIndex}`}
                        sx={[
                          (theme) => styles.bodyCell(theme, rowIndex === selectedRow),
                          cellStyles[column.dataType],
                          bodyCellStyleOverrides,
                          column.width ? {width: column.width} : {},
                        ]}
                        id={`${prefix}${rowIndex}-${colIndex}`}
                      >
                        {cellRenderer(rowIndex + rowsPerPage * currentPage, colIndex)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </LoadingContainer>
      </Paper>
      {pagination && (
        <PaginationControls
          paginationState={paginationState}
          itemLabel={itemLabel}
          includeSizeControls={pagination.sizeControl}
        />
      )}
    </Box>
  );
}

export default Table;
