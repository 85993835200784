import CheckIcon from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {Alert, type AlertProps, Box, Button, Snackbar, Typography} from '@mui/material';
import IconButton from '@pinecone-experience/timber/Buttons/IconButton';

interface SnackbarAlertProps {
  alertText: string;
  itemStatus: AlertProps['severity'];
  onClose: () => void;
  action?: () => void;
  autoHideDuration?: number;
}

function SnackbarAlert({
  alertText,
  itemStatus,
  onClose,
  action,
  autoHideDuration,
}: SnackbarAlertProps) {
  if (!itemStatus || !alertText) {
    return null;
  }
  const snackbarOnClose = (_evt: any, reason: string) => {
    if (reason !== 'clickaway') {
      onClose();
    }
  };
  return (
    <Snackbar
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      key={alertText}
      open={!!itemStatus}
      autoHideDuration={autoHideDuration}
      onClose={snackbarOnClose}
      sx={{mt: -1.5}} // Align snackbar with AppBar
    >
      <Alert
        sx={{maxWidth: '400px', boxShadow: 16, borderRadius: 2, color: 'common.white'}}
        severity={itemStatus}
        iconMapping={{success: <CheckIcon fontSize="inherit" />}}
        variant="standard"
        action={
          <Box display="flex" color="inherit">
            {action && (
              <Button color="inherit" size="small" onClick={action}>
                Click to View
              </Button>
            )}
            <IconButton
              id="alert-close-button"
              color="inherit"
              onClick={onClose}
              icon={<Close fontSize="small" />}
              sx={{m: 0}}
            />
          </Box>
        }
      >
        <Typography fontWeight="bold">{alertText}</Typography>
      </Alert>
    </Snackbar>
  );
}

export default SnackbarAlert;
