import {Typography} from '@mui/material';
import InfoBox from './StyledInfoBox';

interface DateProps {
  created_at?: string;
  updated_at?: string;
  include_title?: boolean;
}

function FormatDates({created_at, updated_at, include_title}: DateProps) {
  if (include_title !== false) {
    include_title = true;
  }
  return (
    <InfoBox label={!include_title ? '' : 'Dates (Created/Updated)'}>
      <Typography variant="body2" fontFamily="monospace" noWrap>
        {created_at || 'N/A'}
      </Typography>
      <Typography variant="body2" fontFamily="monospace" noWrap>
        {updated_at || 'N/A'}
      </Typography>
    </InfoBox>
  );
}

export default FormatDates;
