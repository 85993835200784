import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {getProjectInfo, getProjectServices} from '../../actions/projectActions';
import {getProjectQuotaLimits, getProjectQuotaUtilization} from '../../actions/quotaActions';
import {useSelectedProjectId} from '../../hooks/selectors';
import {useDashboardApi} from '../../hooks/use-api';

function ProjectWrapper() {
  const projectId = useSelectedProjectId();
  const {setQueryData: setProjectQueryData} = useDashboardApi<{projectId: string}>();
  useEffect(() => {
    if (projectId) {
      setProjectQueryData({
        action: getProjectInfo,
        data: {projectId},
      });
    }
  }, [projectId, setProjectQueryData]);

  const {setQueryData: setServicesQueryData} = useDashboardApi<{
    projectId: string;
  }>();
  useEffect(() => {
    if (projectId) {
      setServicesQueryData({
        action: getProjectServices,
        data: {projectId},
      });
    }
  }, [projectId, setServicesQueryData]);

  const {setQueryData: setQuotaLimitsQueryData} = useDashboardApi<{
    projectId: string;
  }>();
  useEffect(() => {
    if (projectId) {
      setQuotaLimitsQueryData({
        action: getProjectQuotaLimits,
        data: {projectId},
      });
    }
  }, [projectId, setQuotaLimitsQueryData]);

  const {setQueryData: setQuotaUtilizationData} = useDashboardApi<{
    projectId: string;
  }>();
  useEffect(() => {
    if (projectId) {
      setQuotaUtilizationData({
        action: getProjectQuotaUtilization,
        data: {projectId},
      });
    }
  }, [projectId, setQuotaUtilizationData]);

  return <Outlet />;
}

export default ProjectWrapper;
