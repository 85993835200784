import {Box, Paper} from '@mui/material';
import {useEffect} from 'react';
import {getUserInfo} from '../../actions/userActions';
import {useSelectedUserId, useUser} from '../../hooks/selectors';
import {useDashboardApi} from '../../hooks/use-api';
import UserCardLarge from '../Cards/User/UserCardLarge';
import UserOrganizationCardList from '../Cards/User/UserOrganizationCardList';
import TitleBox from '../TitleBox';

function UserDetailPage() {
  const userId = useSelectedUserId();
  const {setQueryData} = useDashboardApi<{userId: string}>();
  useEffect(() => {
    if (userId) {
      setQueryData({
        action: getUserInfo,
        data: {userId},
      });
    }
  }, [setQueryData, userId]);
  const user = useUser(userId || '');
  return (
    <Box>
      <TitleBox pageTitle="User Details Page" />
      <Paper sx={{p: 2}}>{user != null && <UserCardLarge {...user} />}</Paper>
      <br />
      <Paper sx={{p: 2}}>{user != null && <UserOrganizationCardList {...user} />}</Paper>
    </Box>
  );
}

export default UserDetailPage;
