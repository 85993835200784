export function decodeErrorMessage(message: string) {
  let decodedMessage;
  try {
    decodedMessage = JSON.parse(message).error.message;
  } catch {
    decodedMessage = message;
  }

  return decodedMessage ?? message;
}
