import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {clearNotification} from '../../actions/notificationActions';
import {useActiveNotification} from '../../hooks/selectors';
import SnackbarAlert from './SnackbarAlert/SnackbarAlert';

function NotificationAlert() {
  const activeNotification = useActiveNotification();
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(clearNotification());
  }, [dispatch]);
  if (!activeNotification) {
    return null;
  }
  return (
    <SnackbarAlert
      itemStatus={activeNotification.type}
      alertText={activeNotification.text}
      onClose={onClose}
      autoHideDuration={3000}
    />
  );
}

export default NotificationAlert;
