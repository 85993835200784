import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import NavigationSidebar from './NavigationSidebar';
import NotificationAlert from './NotificationAlert/NotificationAlert';

const styles = {
  root: {
    backgroundColor: 'background.default',
    height: '100vh',
    display: 'flex',
  },
  content: {
    px: 6,
    height: '100vh',
    width: '100%',
    overflowY: 'auto',
  },
};

function MainScreen() {
  return (
    <Box sx={styles.root}>
      <NavigationSidebar />
      <NotificationAlert />
      <Box sx={styles.content}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default MainScreen;
