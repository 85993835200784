import {createReducer} from '@reduxjs/toolkit';
import * as actions from '../actions/projectActions';
import {type Collection, type Database, type Project} from '../types';

interface ProjectsState {
  loading: boolean;
  items: Record<string, Project | null>;
  servicesLoading: boolean;
  collections: Record<string, Collection[] | null>;
  indexes: Record<string, Database[] | null>;
  userRoles: {
    projectId: string;
    userId: string;
    role: string;
  }[];
}

const initalState = {
  loading: true,
  items: {},
  collections: {},
  indexes: {},
  servicesLoading: true,
  userRoles: [],
} as ProjectsState;

const projectsReducer = createReducer(initalState, (builder) => {
  builder
    .addCase(actions.getProjectInfoRequest, (state) => {
      state.loading = true;
    })
    .addCase(actions.getProjectInfoFailure, (state) => {
      state.loading = false;
    })
    .addCase(actions.getProjectInfoSuccess, (state, action) => {
      state.loading = false;
      state.items[action.payload.project.id] = action.payload.project;
      state.userRoles = state.userRoles.filter(
        (userRole) =>
          !action.payload.users?.find(
            (user) =>
              userRole.projectId === action.payload.project.id && userRole.userId === user.id,
          ),
      );
      state.userRoles.push(
        ...(action.payload.users || []).map((user) => ({
          projectId: action.payload.project.id,
          userId: user.id,
          role: user.role,
        })),
      );
    })
    .addCase(actions.getProjectServicesRequest, (state) => {
      state.servicesLoading = true;
    })
    .addCase(actions.getProjectServicesFailure, (state) => {
      state.servicesLoading = false;
    })
    .addCase(actions.getProjectServicesSuccess, (state, action) => {
      state.servicesLoading = false;
      state.collections[action.payload.projectId] = action.payload.collections;
      state.indexes[action.payload.projectId] = action.payload.databases;
    })
    .addCase(actions.patchProjectOrganizationSuccess, (state, action) => {
      (state.items[action.payload.projectId] as Project).organization_id =
        action.payload.targetOrganizationId;
    });
});

export default projectsReducer;
