import {IconButton} from '@mui/material';
import ChargeDeskIcon from '../../assets/charge_desk_icon_white.png';
import {type Organization} from '../../types';

function ChargeDeskButton(organization: Organization) {
  const handleClick = () => {
    const url = `https://chargedesk.com/manage/pinecone-systems-inc/customers/${organization.stripe_id}`;
    window.open(url, '_blank');
  };

  return organization.stripe_id ? (
    <IconButton onClick={handleClick}>
      <img src={ChargeDeskIcon} alt="ChargeDesk" width={37} height={37} />
    </IconButton>
  ) : (
    <img
      src={ChargeDeskIcon}
      alt="ChargeDesk"
      width={37}
      height={37}
      style={{opacity: 0.5, cursor: 'not-allowed'}}
    />
  );
}

export default ChargeDeskButton;
