import {IconButton} from '@mui/material';
import GrafanaIcon from '../../assets/grafana_icon_white.png';
import {type Organization} from '../../types';

function GrafanaButton(organization: Organization) {
  const handleGrafanaClick = () => {
    const url = `https://pinecone.grafana.net/d/e1559c52-e7e9-41db-a43a-78491b6bf685/customer-details?var-org_id=${organization.id}`;
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={handleGrafanaClick}>
      <img src={GrafanaIcon} alt="Grafana" width={37} height={37} />
    </IconButton>
  );
}

export default GrafanaButton;
