import {useNavigate} from 'react-router-dom';
import {useUser} from '../../hooks/selectors';
import {getAccentColor} from '../../styles/utils';
import StyledChip from '../StyledComponents/StyledChip';

type UserChipProps = {
  userId: string;
  role: string;
};

function UserChip({userId, role}: UserChipProps) {
  const user = useUser(userId);
  const navigate = useNavigate();
  return (
    <StyledChip
      label={`${role}: ${user?.name}`}
      color={getAccentColor(userId)}
      onClick={() => {
        navigate(`/users/${userId}`);
      }}
    />
  );
}

export default UserChip;
