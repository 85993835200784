import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, IconButton, Tooltip, Typography} from '@mui/material';
import type React from 'react';
import {Link} from 'react-router-dom';

interface StyledTitleLinkProps {
  name: string;
  id: string;
  linkTo?: string;
  onClick?: (event: React.MouseEvent) => void;
  nameColor?: string;
  nameFontSize?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2';
}

const StyledTitleLink: React.FC<StyledTitleLinkProps> = ({
  name,
  id,
  linkTo,
  onClick,
  nameColor = 'primary.main',
  nameFontSize = 'h5',
}) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(id);
  };

  const handleButtonClick = (event: React.MouseEvent) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography
        component={linkTo ? Link : 'span'}
        to={linkTo}
        variant={nameFontSize}
        sx={{
          color: nameColor,
          textDecoration: 'none',
        }}
      >
        <strong>{name}</strong>
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        sx={{display: 'flex', alignItems: 'center', ml: 1}}
      >
        {id}
        <Tooltip title="Copy ID">
          <IconButton
            onClick={(e) => {
              handleButtonClick(e);
              copyToClipboard();
            }}
            size="small"
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Typography>
    </Box>
  );
};

export default StyledTitleLink;
