import { Close } from "@carbon/icons-react";
import { IconButton, Modal, Paper } from "@mui/material";
import { type ReactElement } from "react";

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    p: 2,
    maxHeight: "80%",
    maxWidth: '80%',
    overflowY: "auto",
  },
  closeIcon: {
    position: "absolute",
    top: 4,
    right: 4,
  },
};

type StyledModalProps = {
  children: ReactElement;
  closeModal: () => void;
  open: boolean;
  customId: string;
};
function StyledModal({
  children,
  closeModal,
  open,
  customId,
}: StyledModalProps) {
  const onClick = (evt: any) => {
    evt.stopPropagation();
  };
  return (
    <Modal
      sx={styles.modal}
      open={open}
      onClose={closeModal}
      onClick={onClick}
      id={customId}
    >
      <Paper sx={styles.paper} elevation={5}>
        <IconButton sx={styles.closeIcon} onClick={closeModal}>
          <Close size="20" />
        </IconButton>
        {children}
      </Paper>
    </Modal>
  );
}
export default StyledModal;
