import {type Dispatch, createAction} from '@reduxjs/toolkit';
import DashboardApi from '../api/DashboardApi';
import {type Organization, type Role, type UserMember} from '../types';
// eslint-disable-next-line import/no-cycle
import {getProjectInfoSuccess} from './projectActions';
import {getUserInfoSuccess} from './userActions';

export const getOrganizationInfoRequest = createAction<undefined>('GET_ORGANIZATION_INFO_REQUEST');
export const getOrganizationInfoSuccess = createAction<{
  org: Organization;
  users?: UserMember[];
}>('GET_ORGANIZATION_INFO_SUCCESS');
export const getOrganizationInfoFailure = createAction<Error>('GET_ORGANIZATION_INFO_FAILURE');

export function getOrganizationInfo(token: string, data: {organizationId: string}) {
  return (dispatch: Dispatch) => {
    dispatch(getOrganizationInfoRequest());
    return new DashboardApi(token)
      .getOrganizationInfo(data.organizationId)
      .then((res) => {
        dispatch(
          getOrganizationInfoSuccess({
            org: res.organization,
            users: res.users,
          }),
        );
        res.projects.forEach((project) => {
          dispatch(getProjectInfoSuccess({project}));
        });
        res.users.forEach((user) => {
          dispatch(getUserInfoSuccess(user));
        });
      })
      .catch((err) => {
        dispatch(getOrganizationInfoFailure(err));
      });
  };
}

export const patchOrgRequest = createAction<undefined>('PATCH_ORG_REQUEST');
export const patchOrgSuccess = createAction<{
  org: Organization;
}>('PATCH_ORG_SUCCESS');
export const patchOrgFailure = createAction<Error>('PATCH_ORG_FAILURE');

export function patchOrg(
  token: string,
  data: {organizationId: string; data: {pod_quota_override: number}},
) {
  return (dispatch: Dispatch) => {
    dispatch(patchOrgRequest());
    return new DashboardApi(token)
      .patchOrg(data.organizationId, data.data)
      .then((res) => {
        dispatch(patchOrgSuccess({org: res.organization}));
      })
      .catch((err) => {
        dispatch(patchOrgFailure(err));
      });
  };
}

export const patchUserOrgRoleRequest = createAction<{
  organizationId: string;
  userId: string;
  role: Role;
}>('PATCH_USER_ORG_ROLE_REQUEST');

export const patchUserOrgRoleSuccess = createAction<{
  organizationId: string;
  userId: string;
  role: Role;
}>('PATCH_USER_ORG_ROLE_SUCCESS');

export const patchUserOrgRoleFailure = createAction<{
  error: Error;
  data: {organizationId: string; userId: string; role: Role};
}>('PATCH_USER_ORG_ROLE_FAILURE');

export function patchUserOrgRole(
  token: string,
  data: {firebaseId: string; organizationId: string; newRole: Role; userId: string},
) {
  return (dispatch: Dispatch) => {
    dispatch(
      patchUserOrgRoleRequest({
        organizationId: data.organizationId,
        userId: data.userId,
        role: data.newRole,
      }),
    );
    return new DashboardApi(token)
      .patchUserOrgRole(data.firebaseId, data.organizationId, data.newRole)
      .then(() => {
        dispatch(
          patchUserOrgRoleSuccess({
            organizationId: data.organizationId,
            userId: data.userId,
            role: data.newRole,
          }),
        );
      })
      .catch((err) => {
        dispatch(patchUserOrgRoleFailure(err));
      });
  };
}
