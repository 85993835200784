import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import {useState} from 'react';
import {type Organization, type Role, type User} from '../types';

interface PopupProps {
  user: User;
  organization: Organization;
  role?: string;
  open: boolean;
  handleClose: () => void;
  onRoleChange: (newRole: Role) => void;
}

function Popup({user, organization, role, open, handleClose, onRoleChange}: PopupProps) {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  const handleSubmit = () => {
    onRoleChange(selectedOption as Role);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select role</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a new role for {user.email} in the organization {organization.name}. The current
          role is {role}.
        </DialogContentText>
        <FormControl fullWidth margin="dense">
          <InputLabel id="select-role">Select a new role</InputLabel>
          <Select
            labelId="select-role"
            value={selectedOption}
            onChange={handleChange}
            label="Change organization role:"
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="owner">Owner</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Popup;
