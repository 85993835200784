import {useAuth0} from '@auth0/auth0-react';
import {Box, Paper, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import DashboardApi from '../../api/DashboardApi';
import {AUTH0_AUDIENCE} from '../../constants';
import {useSearchError} from '../../hooks/selectors';
import OrganizationSearchCard from '../Cards/Organization/OrganizationSearchCard';
import StyledAlert from '../StyledComponents/StyledAlert';
import StyledSearchBar from '../StyledComponents/StyledSearchBar';
import TitleBox from '../TitleBox';

export default function OrganizationSearchPage() {
  const [token, setToken] = useState('');
  const [results, setResults] = useState([]);
  const {getAccessTokenSilently} = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchLoading, setSearchLoading] = useState(false);
  const query = searchParams.get('query') || '';
  const navigate = useNavigate();

  useEffect(() => {
    getAccessTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      },
    }).then((auth_token: string) => {
      setToken(auth_token);
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (token && query) {
      setSearchLoading(true);
      new DashboardApi(token).searchOrganizations(query).then((res) => {
        setResults(res as any);
        setSearchLoading(false);
      });
    }
  }, [token, searchParams, setResults, query]);

  const onSearch = (str: string) => {
    const trimmedStr = str.trim();
    const userIdRegex = /^-[A-Za-z0-9]+(?:_[A-Za-z0-9]+|-)?[A-Za-z0-9]+-?$/;

    if (userIdRegex.test(trimmedStr)) {
      navigate(`/organizations/${trimmedStr}`);
    } else {
      setSearchParams({query: trimmedStr});
    }
  };

  const errorMessage = useSearchError();
  return (
    <Box>
      <TitleBox pageTitle="Search for Organizations" />
      {token ? <StyledSearchBar search={query} onSearch={onSearch} /> : 'Loading'}
      <LoadingContainer loading={searchLoading}>
        <Paper sx={{marginTop: 2}}>
          {results.length === 0 ? (
            <Typography variant="body1" align="center" padding={1}>
              No organizations found.
            </Typography>
          ) : (
            results.map((organization, i) => (
              <OrganizationSearchCard key={i} organization={organization} />
            ))
          )}
        </Paper>
      </LoadingContainer>
      {errorMessage && <StyledAlert severity="error" title="Error" description={errorMessage} />}
    </Box>
  );
}
