import {IconButton} from '@mui/material';
import Auth0Icon from '../../assets/auth0_clear_icon.svg';
import {type User} from '../../types';

function Auth0Button(user: User) {
  const handleAuth0Click = () => {
    const url = `https://manage.auth0.com/dashboard/us/production-v2-pinecone-io/users/${user.auth0ids}`;
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={handleAuth0Click}>
      <img src={Auth0Icon} alt="Auth0" width={37} height={37} />
    </IconButton>
  );
}

export default Auth0Button;
