import {useAuth0} from '@auth0/auth0-react';
import {Box, Paper, Typography} from '@mui/material';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import DashboardApi from '../../api/DashboardApi';
import {AUTH0_AUDIENCE} from '../../constants';
import {useSearchError} from '../../hooks/selectors';
import ProjectSearchCard from '../Cards/Project/ProjectSearchCard';
import StyledAlert from '../StyledComponents/StyledAlert';
import StyledSearchBar from '../StyledComponents/StyledSearchBar';
import TitleBox from '../TitleBox';

export default function ProjectSearchPage() {
  const [token, setToken] = useState('');
  const [results, setResults] = useState([]);
  const {getAccessTokenSilently} = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchLoading, setSearchLoading] = useState(false);
  const query = searchParams.get('query') || '';
  const navigate = useNavigate();

  useEffect(() => {
    getAccessTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      },
    }).then((auth_token: string) => {
      setToken(auth_token);
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (token && query) {
      setSearchLoading(true);
      new DashboardApi(token).searchProjects(query).then((res) => {
        setResults(res as any);
        setSearchLoading(false);
      });
    }
  }, [token, searchParams, setResults, query]);

  const onSearch = (str: string) => {
    const trimmedStr = str.trim();
    const userIdRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (userIdRegex.test(trimmedStr)) {
      navigate(`/projects/${trimmedStr}`);
    } else {
      setSearchParams({query: trimmedStr});
    }
  };

  const errorMessage = useSearchError();
  return (
    <Box>
      <TitleBox pageTitle="Search for Projects" />
      {token ? <StyledSearchBar search={query} onSearch={onSearch} /> : 'Loading'}
      <LoadingContainer loading={searchLoading}>
        <Paper sx={{marginTop: 2}}>
          {results.length === 0 ? (
            <Typography variant="body1" align="center" padding={1}>
              No projects found.
            </Typography>
          ) : (
            results.map((project, i) => <ProjectSearchCard key={i} project={project} />)
          )}
        </Paper>
      </LoadingContainer>
      {errorMessage && <StyledAlert severity="error" title="Error" description={errorMessage} />}
    </Box>
  );
}
