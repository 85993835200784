import {useAuth0} from '@auth0/auth0-react';
import LoadingPage from './components/LoadingPage';
import MainApp from './components/MainApp';

function App() {
  const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0();
  if (isLoading) {
    return <LoadingPage/>
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return <LoadingPage/>
  }
  return (
    <MainApp />
  );
}

export default App;
