import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {IconButton} from '@mui/material';
import {useState} from 'react';
import {type Organization} from '../../types';
import ChangePodQuotaPopup from '../Cards/Organization/OrganizationChangePodQuotaPopup';

function ChangePodQuotaButton(organization: Organization) {
  const [openPopup, setPopupOpen] = useState(false);

  const handleClick = () => {
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };
  return (
    <>
      <IconButton onClick={handleClick}>
        <SettingsOutlinedIcon fontSize="medium" />
      </IconButton>
      <ChangePodQuotaPopup open={openPopup} organization={organization} handleClose={handleClose} />
    </>
  );
}

export default ChangePodQuotaButton;
