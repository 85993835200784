import {IconButton} from '@mui/material';
import DatadogLogo from '../../assets/dd_icon_white.png';
import {type Database} from '../../types';

interface DatadogButtonProps extends Database {
  size: number;
}

function DatadogButton({size, ...database}: DatadogButtonProps) {
  const handleDatadogClick = () => {
    const isServerless = database.capacity_mode === 'serverless';
    let url;

    if (isServerless) {
      url = `https://preprod.datadoghq.com/dashboard/he2-9az-b3d/serverless-observability?fromUser=true&tpl_var_index_id%5B0%5D=${database.id}&tpl_var_org_id%5B0%5D=*&view=spans&from_ts=1721612329284&to_ts=1721698729284&live=true`;
    } else if (database.rawCrd) {
      url = `https://app.datadoghq.com/dashboard/ei2-dt3-4ss/data-plane-dashboard-updated?fromUser=true&tpl_var_kube_namespace%5B0%5D=${database.rawCrd.status.internalName}&view=spans&from_ts=1721612979569&to_ts=1721699379569&live=true`;
    } else {
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <IconButton onClick={handleDatadogClick}>
      <img src={DatadogLogo} alt="Datadog" width={size} height={size} />
    </IconButton>
  );
}

export default DatadogButton;
