import {useAuth0} from '@auth0/auth0-react';
import {type Dispatch} from '@reduxjs/toolkit';
import {useEffect, useState} from 'react';
import {AUTH0_AUDIENCE} from '../constants';
import {useAppDispatch} from './utils';

export const useDashboardApi = <T>(
  initialAction?: ((token: string, data: T) => (dispatch: Dispatch) => Promise<any>),
  initialQueryData?: Object,
) => {
  const dispatch = useAppDispatch();
  const {getAccessTokenSilently} = useAuth0();
  const [queryData, setQueryData] = useState<{
    action?: ((token: string, data: T) => (dispatch: Dispatch) => Promise<any>),
    data?: T,
  }>({
    action: initialAction,
    data: initialQueryData as T,
  });
  const {action, data} = queryData;
  useEffect(() => {
    getAccessTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      }
    }).then((token: string) => {
      if (action) {
        dispatch(action(token, data as T));
      }
    })
  }, [action, data, dispatch, getAccessTokenSilently]);
  return {
    setQueryData,
  };
};
