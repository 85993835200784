import {Auth0Provider} from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './styles/index.css';
import App from './App';
import {history, store} from './app/store';
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from './constants';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH0_DOMAIN || ''}
      clientId={AUTH0_CLIENT_ID || ''}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      scope="read:current_user"
      cacheLocation="localstorage"
      onRedirectCallback={(appState) => {
        if (appState?.targetUrl) {
          history.replace(appState.targetUrl);
        }
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
